import { configureStore } from "@reduxjs/toolkit";
import infoReducer from "./components/info/infoSlice";
import announcementsReducer from "./features/announcements/announcementsSlice";
import authReducer from "./features/auth/authSlice";
import conversationsReducer from "./features/conversations/conversationsSlice";
import discoverReducer from "./features/discover/discoverSlice";
import linksReducer from "./features/links/linksSlice";
import navReducer from "./features/nav/navSlice";
import nodesReducer from "./features/nodes/nodesSlice";
import dataReducer from "./features/nodes/tabs/data/dataSlice";
import registryReducer from "./features/nodes/tabs/registry/registrySlice";
import orgsReducer from "./features/orgs/orgsSlice";
import activityReducer from "./features/orgs/tabs/activity/activitySlice";
import adminsReducer from "./features/orgs/tabs/admins/adminsSlice";
import donationsReducer from "./features/orgs/tabs/donations/donationsSlice";
import employeesReducer from "./features/orgs/tabs/employees/employeesSlice";
import giftsReducer from "./features/orgs/tabs/gifts/giftsSlice";
import personalizeReducer from "./features/orgs/tabs/personalize/personalizeSlice";
import usageReducer from "./features/orgs/tabs/usage/usageSlice";
import servicesReducer from "./features/services/servicesSlice";
import supportReducer from "./features/support/supportSlice";
import surveysReducer from "./features/surveys/surveysSlice";
import inferencesReducer from "./features/users/tabs/inferences/inferencesSlice";
import remindersReducer from "./features/users/tabs/reminders/remindersSlice";
import usersReducer from "./features/users/usersSlice";
import waitlistReducer from "./features/waitlist/waitlistSlice";
import workersReducer from "./features/workers/workersSlice";

export default configureStore({
  reducer: {
    auth: authReducer,
    nav: navReducer,
    orgs: orgsReducer,
    usage: usageReducer,
    activity: activityReducer,
    donations: donationsReducer,
    info: infoReducer,
    personalize: personalizeReducer,
    admins: adminsReducer,
    employees: employeesReducer,
    gifts: giftsReducer,
    discover: discoverReducer,
    users: usersReducer,
    nodes: nodesReducer,
    data: dataReducer,
    conversations: conversationsReducer,
    registry: registryReducer,
    waitlist: waitlistReducer,
    workers: workersReducer,
    services: servicesReducer,
    announcements: announcementsReducer,
    support: supportReducer,
    surveys: surveysReducer,
    links: linksReducer,
    inferences: inferencesReducer,
    reminders: remindersReducer,
  },
});
