import {
  BankOutlined,
  CopyrightOutlined,
  CustomerServiceOutlined,
  DatabaseOutlined,
  LinkOutlined,
  LogoutOutlined,
  MessageOutlined,
  ProfileOutlined,
  SettingOutlined,
  SoundOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Col, Dropdown, Layout, Menu, Row, Space, Spin } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import logo from "../../assets/img/logo_nav.svg";
import { Logo } from "../../components/logo/Logo";
import { Announcements } from "../announcements/Announcements";
import { authSelector, logout, validate } from "../auth/authSlice";
import { Chat } from "../chat/Chat";
import { Conversations } from "../conversations/Conversations";
import { Discover } from "../discover/Discover";
import { Links } from "../links/Links";
import { NodeDetail } from "../nodes/NodeDetail";
import { Nodes } from "../nodes/Nodes";
import { OrgDetail } from "../orgs/OrgDetail";
import { Orgs } from "../orgs/Orgs";
import { Profile } from "../profile/Profile";
import { Services } from "../services/Services";
import { Support } from "../support/Support";
import { Surveys } from "../surveys/Surveys";
import { UserDetail } from "../users/UserDetail";
import { Users } from "../users/Users";
import { Workers } from "../workers/Workers";
import styles from "./Nav.module.css";

export function Nav() {
  const location = useLocation();
  let { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { Header, Content } = Layout;

  const { currentUser, validateLoading } = useSelector(authSelector);

  useEffect(() => {
    dispatch(validate());
  }, [dispatch]);

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/conversations");
    }
  }, [location]);

  const getContent = () => {
    let path = location.pathname;
    switch (true) {
      case /^\/profile$/.test(path):
        return <Profile />;
      case /^\/orgs$/.test(path):
        return <Orgs />;
      case /orgs\/([\s\S]*)$/.test(path):
        return <OrgDetail id={id} />;
      case /^\/discover$/.test(path):
        return <Discover />;
      case /discover\/([\s\S]*)$/.test(path):
        return <Discover id={id} />;
      case /^\/users$/.test(path):
        return <Users />;
      case /users\/([\s\S]*)$/.test(path):
        return <UserDetail id={id} />;
      case /^\/nodes$/.test(path):
        return <Nodes />;
      case /nodes\/([\s\S]*)$/.test(path):
        return <NodeDetail id={id} />;
      case /^\/services$/.test(path):
        return <Services />;
      case /^\/conversations$/.test(path):
        return <Conversations />;
      case /conversations\/([\s\S]*)$/.test(path):
        return <Conversations id={id} />;
      case /^\/chat$/.test(path):
        return <Chat />;
      case /^\/announcements$/.test(path):
        return <Announcements />;
      case /^\/support$/.test(path):
        return <Support />;
      case /^\/surveys$/.test(path):
        return <Surveys />;
      case /^\/links$/.test(path):
        return <Links />;
      case /^\/workers$/.test(path):
        return <Workers />;
      default:
        return <Orgs />;
    }
  };

  return (
    <>
      {validateLoading ? (
        <div className={styles.loadingContainer}>
          <Spin size="large" />
        </div>
      ) : (
        <Layout className={styles.layout}>
          <Header className={styles.header}>
            <Row>
              <Col span={4}>
                <div>
                  <Link to="/">
                    <img src={logo} alt="Humanitas" className={styles.logo} />
                  </Link>
                </div>
              </Col>
              <Col span={16}>
                <Menu
                  className={styles.nav}
                  // selectedKeys={location.pathname}
                  selectedKeys={`/${location.pathname.split("/")[1]}`}
                  mode="horizontal"
                  triggerSubMenuAction="click"
                  onSelect={(data) => {
                    if (
                      location.pathname === "/chat" &&
                      location.search.length > 0
                    ) {
                      const confirm = window.confirm(
                        "Are you sure you want to move away from this tab? Once you move away, your current chat will automatically close and summary will be generated. If you wish to continue this chat, please open a new tab and continue your admin operations there."
                      );
                      if (confirm) {
                        navigate(`${data.key}`);
                      }
                    } else {
                      navigate(`${data.key}`);
                    }
                  }}
                  items={[
                    {
                      key: "/conversations",
                      label: <MessageOutlined className={styles.icon} />,
                    },
                    {
                      key: "/users",
                      label: <UserOutlined className={styles.icon} />,
                    },
                    {
                      key: "/orgs",
                      label: <BankOutlined className={styles.icon} />,
                    },
                    {
                      key: "/services",
                      label: <DatabaseOutlined className={styles.icon} />,
                    },
                    // {
                    //   key: "/nodes",
                    //   label: <ClusterOutlined className={styles.icon} />,
                    // },
                    // {
                    //   key: "/discover",
                    //   label: <SearchOutlined className={styles.icon} />,
                    // },

                    {
                      key: "/announcements",
                      label: <SoundOutlined className={styles.icon} />,
                    },
                    {
                      key: "/surveys",
                      label: <ProfileOutlined className={styles.icon} />,
                    },
                    {
                      key: "/support",
                      label: (
                        <CustomerServiceOutlined className={styles.icon} />
                      ),
                    },
                    {
                      key: "/links",
                      label: <LinkOutlined className={styles.icon} />,
                    },
                    {
                      key: "/chat",
                      label: <CopyrightOutlined className={styles.icon} />,
                    },
                    {
                      key: "/workers",
                      label: <SettingOutlined className={styles.icon} />,
                    },
                  ]}
                />
              </Col>
              <Col span={4} className={styles.avatar}>
                <Space size="large" className="mr-20">
                  <Dropdown
                    menu={{
                      items: [
                        {
                          key: "profile",
                          icon: <UserOutlined className={styles.icon} />,
                          label: "Profile",
                          onClick: () => navigate("/profile"),
                        },
                        {
                          key: "logout",
                          icon: <LogoutOutlined className={styles.icon} />,
                          label: "Logout",
                          onClick: () => dispatch(logout()),
                        },
                      ],
                    }}
                    trigger={["click"]}
                  >
                    <div className="pointer">
                      <Logo
                        type="user"
                        size="lg"
                        data={{
                          name: currentUser.f_name,
                          src: currentUser.avatar,
                        }}
                      />
                    </div>
                  </Dropdown>
                </Space>
              </Col>
            </Row>
          </Header>
          <Layout className={styles.mainContent}>
            <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
              {getContent()}
            </Content>
          </Layout>
        </Layout>
      )}
    </>
  );
}
