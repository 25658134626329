import { DownloadOutlined, QuestionCircleOutlined } from "@ant-design/icons";

import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Table,
  Tooltip,
  Typography,
} from "antd";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { personaTags } from "../../constants";
import { listOrgs, orgsSelector } from "../orgs/orgsSlice";
import { searchServiceIds } from "../services/servicesSlice";
import { searchSurveyIds } from "../surveys/surveysSlice";
import {
  announcementsSelector,
  createAnnouncement,
  downloadAnnouncements,
  getAnnouncements,
} from "./announcementsSlice";

const { RangePicker } = DatePicker;
const PAGE_SIZE = 25;
const { TextArea } = Input;
const { Paragraph } = Typography;
dayjs.extend(utc);
dayjs.extend(timezone);

export function Announcements() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [exportForm] = Form.useForm();
  const {
    data: announcements,
    loading,
    errors,
    totalPages,
    totalCount,
  } = useSelector(announcementsSelector);
  const [pageNum, setPageNum] = useState(1);
  const { orgsList } = useSelector(orgsSelector);
  const [serviceIds, setServiceIds] = useState([]);
  const [surveyIds, setSurveyIds] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);
  const [exportOpened, setExportOpened] = useState(false);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error(errors[0]);
    }
  }, [errors]);

  useEffect(() => {
    dispatch(getAnnouncements({ page: pageNum, per: PAGE_SIZE }));
    dispatch(listOrgs());
  }, []);

  const onPageChange = (page) => {
    setPageNum(page);
    dispatch(getAnnouncements({ page, per: PAGE_SIZE }));
  };

  const searchService = (val) => {
    setServiceIds([]);
    dispatch(
      searchServiceIds({
        query: val,
        page: 1,
        per: 10,
        filters: {},
      })
    ).then((res) => {
      if (res.type === "services/searchIds/fulfilled") {
        setServiceIds(res.payload.results);
      } else {
        message.error("Error retrieving service ids");
      }
    });
  };

  const searchSurvey = (val) => {
    setSurveyIds([]);
    dispatch(searchSurveyIds({ query: val })).then((res) => {
      if (res.type === "surveys/searchSurveyIds/fulfilled") {
        setSurveyIds(res.payload.results);
      } else {
        message.error({
          content: "Error retrieving data",
        });
      }
    });
  };

  const handleSend = (values) => {
    let scope = values.scope;

    let delay = null;
    if (values.send_at) {
      const timeNow = dayjs()
        .tz("America/Los_Angeles")
        .format("YYYY-MM-DD HH:mm");
      const timeToSend = values.send_at.format("YYYY-MM-DD HH:mm");
      const diff = dayjs(timeToSend).diff(dayjs(timeNow));
      if (diff <= 0) {
        message.error({
          content: "Announcement send time cannot be earlier than current time",
        });
        return;
      }
      delay = diff;
    }

    Modal.confirm({
      title: "Confirm",
      content: (
        <>
          <div>Are you sure you want to send this SMS?</div>
          <div className="fw-600 mt-10">{values.message}</div>
          {values?.service_ids?.length && (
            <div className="fw-400">{`Service IDs: ${
              values?.service_ids?.join(", ") || ""
            }`}</div>
          )}
          {values?.survey_ids?.length && (
            <div className="fw-400">{`Survey IDs: ${
              values?.survey_ids?.join(", ") || ""
            }`}</div>
          )}
          <div className="mt-5">
            <Paragraph
              ellipsis={{
                rows: 4,
                expandable: true,
                symbol: "more",
              }}
              className="fs-1"
            >
              {values.context}
            </Paragraph>
          </div>
          <div className="fs-2 c-divider mt-5 mb-5">TO</div>
          {!scope.org_ids?.length &&
            !scope.user_ids?.length &&
            !scope.personas?.length && <div className="fw-500">Everyone</div>}
          {scope.org_ids?.length > 0 && (
            <div>{`Orgs: ${scope.org_ids
              .map((o) => orgsList.find((x) => x.id === o).name)
              .join(" (or) ")}`}</div>
          )}
          {scope.personas?.length > 0 && (
            <div>{`Personas: ${scope.personas.join(" (or) ")}`}</div>
          )}
          {scope.user_ids?.length > 0 && (
            <div>{`User IDs: ${scope.user_ids.join(", ")}`}</div>
          )}
          <div className="mt-20">
            <div>
              {"-> "}
              {values.send_at
                ? `Send at ${dayjs(values.send_at).format(
                    "YYYY-MM-DD HH:mm"
                  )} PST`
                : "Send Immediately"}
            </div>
            <div>
              {"-> "}
              {values.add_chat ? "Add to Chat Flow" : "Don't add to Chat Flow"}
            </div>
          </div>
        </>
      ),
      okText: "Send",
      cancelText: "Cancel",
      onOk: () => {
        if (scope.user_ids) {
          scope.user_ids = scope.user_ids.map((x) => parseInt(x));
        }

        dispatch(
          createAnnouncement({
            message: values.message,
            context: values.context || null,
            add_chat: values.add_chat,
            service_ids: values.service_ids || [],
            survey_ids: values.survey_ids || [],
            delay,
            scope,
          })
        ).then((res) => {
          if (res.type === "announcements/create/fulfilled") {
            form.resetFields();
            dispatch(getAnnouncements({ page: pageNum, per: PAGE_SIZE }));
          } else {
            message.error({
              content: "Failed to send SMS",
            });
          }
        });
      },
    });
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Service IDs",
      dataIndex: "service_ids",
      key: "service_ids",
      render: (service_ids) => service_ids.join(", ") || "",
    },
    {
      title: "Survey IDs",
      dataIndex: "survey_ids",
      key: "survey_ids",
      render: (survey_ids) => survey_ids.join(", ") || "",
    },
    {
      title: "Context",
      dataIndex: "context",
      key: "context",
      render: (context) =>
        context && (
          <Tooltip
            title={context}
            color="#4867b1"
            trigger="click"
            placement="right"
            overlayInnerStyle={{ width: "500px" }}
          >
            <span className="link">show</span>
          </Tooltip>
        ),
    },
    {
      title: "Sent to",
      dataIndex: "scope",
      key: "scope",
      render: (scope) => (
        <>
          {!scope?.org_ids?.length && !scope?.user_ids?.length && (
            <span className="fw-600">Everyone</span>
          )}
          {scope?.org_ids?.length > 0 && (
            <div>
              Employees of:{" "}
              <span className="fw-600">
                {scope.org_ids
                  .map((o) => orgsList.find((x) => x.id === o)?.name)
                  .join(", ")}
              </span>
            </div>
          )}
          {scope?.user_ids?.length > 0 && (
            <div>
              User IDs:{" "}
              <span className="fw-600">{scope.user_ids.join(", ")}</span>
            </div>
          )}
          {scope?.personas?.length > 0 && (
            <div>
              Personas:{" "}
              <span className="fw-600">{scope.personas.join(", ")}</span>
            </div>
          )}
        </>
      ),
    },
    {
      title: "Delay (mins)",
      dataIndex: "delay",
      key: "delay",
      render: (delay) => (delay ? delay / 60000 : ""),
    },
    {
      title: "Add to Chat",
      dataIndex: "add_chat",
      key: "add_chat",
      render: (add_chat) => (add_chat ? "Yes" : "No"),
    },
    {
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => new Date(created_at).toLocaleString(),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (status === 2 ? "Sent" : "Queued"),
    },
    {
      title: "Reached",
      dataIndex: "reached_c",
      key: "reached_c",
    },
    {
      title: (
        <span>
          Reacted&nbsp;&nbsp;
          <Tooltip title="Responded within 48 hours" color="#4867b1">
            <QuestionCircleOutlined />
          </Tooltip>
        </span>
      ),
      dataIndex: "reacted_c",
      key: "reacted_c",
    },
  ];

  const exportAnnouncements = (values) => {
    setExportLoading(true);
    dispatch(
      downloadAnnouncements({
        start: values.range[0].toISOString(),
        end: values.range[1].toISOString(),
      })
    ).then((res) => {
      setExportLoading(false);
      if (res.type === "announcements/download/fulfilled") {
        const url = window.URL.createObjectURL(new Blob([res.payload]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `announcements_${values.range[0].toISOString()}_to_${values.range[1].toISOString()}.csv`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();

        setExportOpened(false);
        exportForm.resetFields();
      } else {
        message.error("Failed to export announcements");
      }
    });
  };

  return (
    <>
      <div className="container">
        <div className="mb-15">
          <Row justify="space-between" wrap>
            <Col>
              <span className="fs-5 fw-300">Announcements</span>
            </Col>
          </Row>
        </div>
        <div className="container bg-white b-shadow">
          <Form
            form={form}
            disabled={loading}
            requiredMark={false}
            initialValues={{
              scope: { org_ids: [], personas: [], user_ids: [] },
              add_chat: true,
            }}
            onFinish={handleSend}
            onFieldsChange={(changed) => {
              if (changed.length > 0 && changed[0].name[0] === "scope") {
                switch (changed[0].name[1]) {
                  case "org_ids":
                  case "personas":
                    form.setFieldsValue({ scope: { user_ids: [] } });
                    break;
                  case "user_ids":
                    form.setFieldsValue({
                      scope: { org_ids: [], personas: [] },
                    });
                    break;
                  default:
                    break;
                }
              }
            }}
          >
            <Row gutter={24} align="middle">
              <Col span={21}>
                <div>
                  <Form.Item
                    name="message"
                    rules={[
                      {
                        required: true,
                        message: "Please input a valid message",
                      },
                    ]}
                  >
                    <TextArea
                      autoSize={true}
                      placeholder="Type message to send. One SMS segment is 160 characters. Cost is per segment sent per number."
                      showCount={true}
                      maxLength={320}
                    />
                  </Form.Item>
                </div>
                <div className="mt-30">
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item name="service_ids">
                        <Select
                          placeholder="Select Service IDs for the services you mentioned in your message"
                          allowClear
                          showSearch
                          mode="multiple"
                          options={serviceIds.map((t) => ({
                            label: `${t.id} - ${t.program}`,
                            value: t.id,
                          }))}
                          onSearch={searchService}
                          onDropdownVisibleChange={() => setServiceIds([])}
                        ></Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="survey_ids">
                        <Select
                          placeholder="Select Survey IDs for the surveys you mentioned in your message"
                          allowClear
                          showSearch
                          mode="multiple"
                          options={surveyIds.map((t) => ({
                            label: `${t.id} - ${t.name}`,
                            value: t.id,
                          }))}
                          onSearch={searchSurvey}
                          onDropdownVisibleChange={() => setSurveyIds([])}
                        ></Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <div className="mt-30">
                  <Form.Item name="context">
                    <TextArea
                      autoSize={{ maxRows: 5 }}
                      placeholder="Any additional context to be added to the message"
                    />
                  </Form.Item>
                </div>
                <Divider>
                  <span className="c-divider">TO</span>
                </Divider>
                <div>
                  <Row gutter={16} align="middle">
                    <Col span={2}>
                      <div className="c-subtext fw-500 ta-c">Everyone</div>
                    </Col>
                    <Col span={1}>
                      <div className="fs-1 fw-300 c-subtext ta-c">(OR)</div>
                    </Col>
                    <Col span={4}>
                      <Form.Item name={["scope", "org_ids"]}>
                        <Select
                          allowClear
                          showSearch
                          mode="multiple"
                          placeholder="Orgs"
                          options={orgsList.map((o) => ({
                            label: o.name,
                            value: o.id,
                          }))}
                        ></Select>
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item name={["scope", "personas"]}>
                        <Select
                          allowClear
                          showSearch
                          mode="multiple"
                          placeholder="Personas"
                          options={personaTags
                            .filter((o) => o !== "all-users")
                            .map((o) => ({
                              label: o,
                              value: o,
                            }))}
                        ></Select>
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <div className="fs-1 fw-300 c-subtext ta-c">(OR)</div>
                    </Col>
                    <Col span={4}>
                      <Form.Item name={["scope", "user_ids"]}>
                        <Select
                          mode="tags"
                          allowClear
                          placeholder="User IDs"
                          tokenSeparators={[","]}
                        ></Select>
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <div className="c-subtext ta-c">{`-->`}</div>
                    </Col>
                    <Col span={4}>
                      <Form.Item name="send_at">
                        <DatePicker
                          showNow={false}
                          showTime={{ format: "HH:mm" }}
                          format="YYYY-MM-DD HH:mm"
                          minDate={dayjs().tz("America/Los_Angeles")}
                          placeholder="Send at"
                          renderExtraFooter={() =>
                            "Pacific Standard Time (PST)"
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item name="add_chat">
                        <Select
                          options={[
                            {
                              value: true,
                              label: "Add Chat",
                            },
                            {
                              value: false,
                              label: "Don't add Chat",
                            },
                          ]}
                        ></Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col span={3} className="ta-r">
                <Button type="primary" htmlType="submit">
                  Send SMS
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
        <div className="container bg-white b-shadow">
          <div className="ta-r">
            <Button
              icon={<DownloadOutlined />}
              onClick={() => setExportOpened(true)}
            >
              Export
            </Button>
          </div>
          <Table
            loading={loading}
            bordered
            size="small"
            className="mt-15"
            columns={columns}
            dataSource={announcements.map((l, i) => {
              return { ...l, ...{ key: i } };
            })}
            pagination={{
              position: ["bottomRight"],
              onChange: onPageChange,
              pageSize: PAGE_SIZE,
              total: totalPages * PAGE_SIZE,
              showSizeChanger: false,
              current: pageNum,
              showTotal: () => (
                <span className="fs-2 c-subtext">{`${totalCount} ${
                  totalCount === 1 ? "announcement" : "announcements"
                }`}</span>
              ),
            }}
          />
        </div>
      </div>

      <Modal
        title="Export Announcements"
        open={exportOpened}
        footer={null}
        width={500}
        onCancel={() => {
          setExportOpened(false);
          exportForm.resetFields();
        }}
      >
        <div className="mt-30">
          <Form
            form={exportForm}
            requiredMark={false}
            onFinish={exportAnnouncements}
            layout="vertical"
          >
            <Form.Item
              name="range"
              label="Date Range (in PST)"
              rules={[{ required: true, message: "Please input date range" }]}
            >
              <RangePicker
                showTime={{
                  format: "HH:mm",
                }}
              />
            </Form.Item>
            <Form.Item className="ta-r mt-35 mb-10">
              <Button type="primary" htmlType="submit" loading={exportLoading}>
                Export
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
}
