import {
  FrownFilled,
  LoadingOutlined,
  MehFilled,
  SmileFilled,
  SmileOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Alert, Col, Divider, Row, Space, Tag, Timeline, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getPersona, ModelCosts, Models } from "../../constants";
import { getLatestSummary } from "../users/usersSlice";
import styles from "./Conversations.module.css";
import {
  chatsSelector,
  clearChats,
  clearCurrentConversation,
  clearEvents,
  currentConversationSelector,
  eventsSelector,
  getChats,
  getConversation,
  getEvents,
} from "./conversationsSlice";

export function Chats(props) {
  const dispatch = useDispatch();
  const {
    name,
    control_id: controlId,
    user_id: userId,
    node_id: nodeId,
    created_at: createdAt,
    sentiment,
  } = props.conversation;
  const [finishedAt, setFinishedAt] = useState(props.conversation.finished_at);
  const [finishedReason, setFinishedReason] = useState(
    props.conversation.finished_reason
  );
  const [failed, setFailed] = useState(props.conversation.failed);
  const { data: chats, loading, errors } = useSelector(chatsSelector);
  const { data: events } = useSelector(eventsSelector);
  const { data: currentConversation } = useSelector(
    currentConversationSelector
  );
  const [summary, setSummary] = useState({});
  const [totalUsage, setTotalUsage] = useState({});
  const [modalCost, setModalCost] = useState(0);

  const getUsage = (usage) => {
    return (
      usage && (
        <>
          p: {usage.p}, c: {usage.c}
        </>
      )
    );
  };

  const getData = () => {
    dispatch(
      getChats({
        conversationId: props.conversation?.id,
      })
    );
    dispatch(
      getEvents({
        conversationId: props.conversation?.id,
      })
    );
    dispatch(
      getConversation({
        conversationId: props.conversation?.id,
      })
    );
  };

  useEffect(() => {
    if (finishedAt) {
      dispatch(
        getLatestSummary({ conversationId: props.conversation?.id, userId })
      ).then((res) => {
        if (res.type === "users/latestSummary/fulfilled") {
          setSummary(res.payload);
        }
      });
    }
  }, [finishedAt]);

  useEffect(() => {
    if (currentConversation?.finished_at) {
      setFinishedAt(currentConversation?.finished_at);
      setFinishedReason(currentConversation?.finished_reason);
    }
    if (currentConversation?.failed) {
      setFailed(currentConversation?.failed);
    }
  }, [currentConversation]);

  useEffect(() => {
    if (props.conversation) {
      getData();
    }

    return () => {
      dispatch(clearChats());
      dispatch(clearEvents());
      dispatch(clearCurrentConversation());
    };
  }, [props.conversation, dispatch]);

  useEffect(() => {
    let intervalId;
    if (controlId && !finishedAt) {
      intervalId = setInterval(() => {
        console.log("Polling for updates");
        getData();
      }, 2000);
    }

    return () => {
      if (intervalId) {
        console.log("Cleared interval", intervalId);
        clearInterval(intervalId);
      }
    };
  }, [createdAt, finishedAt]);

  useEffect(() => {
    let usage = chats.reduce(
      (acc, c) => getTotalUsage(acc, c.model, c.token_usage),
      {}
    );
    if (summary?.id) {
      usage = getTotalUsage(
        usage,
        summary.meta.model,
        summary.meta.token_usage
      );
    }
    setTotalUsage(usage);
  }, [chats, summary]);

  const getTotalUsage = (prevUsage, model, usage) => {
    const newUsage = { ...prevUsage };
    if (model) {
      let modelName = Models[model];
      if (newUsage[modelName]) {
        newUsage[modelName].p += usage.p;
        newUsage[modelName].c += usage.c;
      } else {
        newUsage[modelName] = { p: usage.p, c: usage.c };
      }
    }
    setModalCost(
      Object.keys(newUsage).reduce((acc, key) => {
        const modelCost =
          ModelCosts[Object.keys(Models).find((m) => Models[m] === key)];
        return (
          acc +
          (modelCost.p * newUsage[key].p + modelCost.c * newUsage[key].c) /
            1000000
        );
      }, 0)
    );
    return newUsage;
  };

  const renderToolCalls = (c, toolCalls) => {
    return toolCalls.map((t, i) => (
      <div key={i}>
        <div className="mb-15">
          {JSON.stringify({
            name: t.name,
            arguments:
              typeof t.arguments === "string"
                ? JSON.parse(t.arguments)
                : t.arguments,
          })}
        </div>
        <div className={styles.time}>
          <>{c.id}&nbsp;|&nbsp;</>
          {c.system && <>{getPersona(c.persona_id)}&nbsp;|&nbsp;</>}
          {c.model && (
            <>
              {Models[c.model]}&nbsp;|&nbsp;{getUsage(c.token_usage)}
              &nbsp;|&nbsp;
            </>
          )}
          {new Date(c.created_at).toLocaleString()}
        </div>
      </div>
    ));
  };

  const renderSummary = (title, data, model, usage, created_at) => {
    return (
      <div className="mt-20" key={title}>
        <div className={styles.summary}>
          {typeof data === "string" ? data : JSON.stringify(data)}
          <div className={styles.time}>
            {model && (
              <>
                {title}&nbsp;|&nbsp;{Models[model]}
                &nbsp;|&nbsp;
                {getUsage(usage)}
                &nbsp;|&nbsp;
              </>
            )}
            {new Date(created_at).toLocaleString()}
          </div>
        </div>
      </div>
    );
  };

  const formatChats = () => {
    return chats.map((c, i) =>
      c.tool_calls.length > 0 ? (
        <div className={styles.tool} key={i}>
          {renderToolCalls(c, c.tool_calls)}
        </div>
      ) : c.other_tool_calls.length > 0 ? (
        <div className={styles.tool} key={i}>
          {renderToolCalls(c, c.other_tool_calls)}
        </div>
      ) : Object.keys(c.tool_response).length > 0 ? (
        <div className={styles.tool} key={i}>
          <div className="mb-15">
            {JSON.stringify({
              name: c.tool_response.name,
              response: JSON.parse(c.tool_response.response),
            })}
          </div>
          <div className={styles.time}>
            <>{c.id}&nbsp;|&nbsp;</>
            {c.system && <>{getPersona(c.persona_id)}&nbsp;|&nbsp;</>}
            {c.model && (
              <>
                {Models[c.model]}&nbsp;|&nbsp;{getUsage(c.token_usage)}
                &nbsp;|&nbsp;
              </>
            )}
            {new Date(c.created_at).toLocaleString()}
          </div>
        </div>
      ) : (
        <div className={c.system ? styles.system : styles.user} key={i}>
          <div>
            <Space size={24} align="top">
              <div className={styles.avatar}>
                {c.system ? <SmileOutlined /> : <UserOutlined />}
              </div>
              <div>
                {c.message.split("--").map((part, index) =>
                  index % 2 === 0 ? (
                    <span key={index} style={{ whiteSpace: "pre-wrap" }}>
                      {part}
                    </span>
                  ) : (
                    <span key={index} className="fs-1 c-subtext">
                      {part}
                    </span>
                  )
                )}
                <div className={styles.translation}>
                  {c.meta?.pr && `Translated to User: ${c.meta?.pr}`}
                  {c.meta?.og && `Original Message: ${c.meta?.og}`}
                </div>
              </div>
            </Space>
            <div className={styles.time}>
              {c.meta["rId"] ? `Reminder #${c.meta["rId"]} | ` : ""}
              {c.meta["aId"] ? `Announcement #${c.meta["aId"]} | ` : ""}
              <>{c.id}&nbsp;|&nbsp;</>
              {c.system && <>{getPersona(c.persona_id)}&nbsp;|&nbsp;</>}
              {c.model && (
                <>
                  {Models[c.model]}&nbsp;|&nbsp;{getUsage(c.token_usage)}
                  &nbsp;|&nbsp;
                </>
              )}
              {new Date(c.created_at).toLocaleString()}
            </div>
          </div>
        </div>
      )
    );
  };

  const getTimeDiff = (start, finish) => {
    const diff = Math.abs(new Date(finish) - new Date(start));
    const m = Math.floor(diff / 60000);
    const s = Math.floor((diff % 60000) / 1000);
    return `${m}m ${s}s`;
  };

  return (
    <>
      {failed && (
        <Alert
          message={failed?.reason}
          description={failed?.meta}
          className="mt-20 mb-20"
          type="error"
          showIcon
        />
      )}
      <div className={styles.header}>
        <Row gutter={24}>
          {controlId ? (
            <>
              <Col span={5}>
                <div className={styles.key}>Control ID</div>
                <div className={styles.val}>
                  <div className="fs-1">{controlId}</div>
                </div>
              </Col>
              <Col span={3}>
                <div className={styles.key}>User ID</div>
                <div className={styles.val}>
                  <Link to={`/users/${userId}`}>{userId}</Link>
                </div>
              </Col>
              <Col span={5}>
                <div className={styles.key}>Estimated Usage</div>
                <div className={styles.val}>
                  <div className="fs-2 fw-600 mb-10">
                    Model Cost:{" "}
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(modalCost)}
                  </div>
                  {Object.keys(totalUsage).map((u, i) => (
                    <div key={i} className="mb-10">
                      <div className="fs-2">{u}</div>
                      <div className="fs-1">
                        Prompt: {totalUsage[u].p} tokens
                      </div>
                      <div className="fs-1">
                        Completion: {totalUsage[u].c} tokens
                      </div>
                    </div>
                  ))}
                </div>
              </Col>
              <Col span={5}>
                <div className={styles.key}>Duration</div>
                <div className={styles.val}>
                  {finishedAt ? (
                    <>
                      {name === "Casey Call" ? (
                        <div>{getTimeDiff(createdAt, finishedAt)}</div>
                      ) : (
                        ""
                      )}
                      <div className="c-subtext fs-1">
                        <span className="fw-600">Hangup reason:</span>{" "}
                        {finishedReason}
                      </div>
                    </>
                  ) : (
                    <Tag icon={<LoadingOutlined />} color="green">
                      Live
                    </Tag>
                  )}
                </div>
                <div className="mt-10">
                  {sentiment?.emotion && (
                    <Tooltip title={sentiment?.reason} color="#4867b1">
                      {sentiment?.emotion === "positive" ? (
                        <SmileFilled
                          className="fs-3"
                          style={{ color: "#49aa19" }}
                        />
                      ) : sentiment?.emotion === "negative" ? (
                        <FrownFilled style={{ color: "#dc4446" }} />
                      ) : (
                        <MehFilled style={{ color: "#faad14" }} />
                      )}
                    </Tooltip>
                  )}
                </div>
              </Col>
              <Col span={5}>
                <div className={styles.key}>Meta Data</div>
                <div className={styles.val}>
                  {currentConversation?.meta?.service_ids?.length > 0 && (
                    <div className="c-subtext fs-1">
                      <span className="fw-600">Interacted Services:</span>{" "}
                      {currentConversation?.meta?.service_ids?.join(", ")}
                    </div>
                  )}
                  {currentConversation?.meta?.exclude_services?.length > 0 && (
                    <div className="c-subtext fs-1 mt-5">
                      <div className="fw-600">Excluded Services:</div>
                      {currentConversation?.meta?.exclude_services?.map(
                        (s, i) => (
                          <div key={i}>
                            {s.id} - {s.reason}
                          </div>
                        )
                      )}
                    </div>
                  )}
                </div>
              </Col>
            </>
          ) : (
            <>
              <Col span={6}>
                <div className={styles.key}>User</div>
                <div className={styles.val}>
                  <Link to={`/users/${userId}`}>{userId}</Link>
                </div>
              </Col>
              <Col span={6}>
                <div className={styles.key}>Node</div>
                <div className={styles.val}>
                  <Link to={`/nodes/${nodeId}`}>{nodeId}</Link>
                </div>
              </Col>
              <Col span={6}>
                <div className={styles.key}>Estimated Usage</div>
                <div className={styles.val}>
                  <div className="fs-2 fw-600 mb-10">
                    Model Cost:{" "}
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(modalCost)}
                  </div>
                  {Object.keys(totalUsage).map((u, i) => (
                    <div key={i} className="mb-10">
                      <div className="fs-2">{u}</div>
                      <div className="fs-1">
                        Prompt: {totalUsage[u].p} tokens
                      </div>
                      <div className="fs-1">
                        Completion: {totalUsage[u].c} tokens
                      </div>
                    </div>
                  ))}
                </div>
              </Col>
            </>
          )}
        </Row>
      </div>

      <div className="mt-20">
        {name === "Casey Call" ? (
          <Row gutter={24}>
            <Col span={19}>{formatChats()}</Col>
            <Col span={5}>
              <div className={styles.eventBox}>
                <div className={styles.title}>Event Log</div>
                <div className="mt-20">
                  <Timeline
                    items={events.map((e) => {
                      return {
                        children: (
                          <>
                            <Tag>{e.name}</Tag>
                            <div className={styles.at}>
                              {new Date(e.created_at).toLocaleString()}
                            </div>
                          </>
                        ),
                      };
                    })}
                  />
                </div>
              </div>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col span={24}>{formatChats()}</Col>
          </Row>
        )}
      </div>

      {summary?.id && (
        <>
          <Divider />
          {renderSummary(
            "Summary",
            summary.summary,
            summary.meta.model,
            summary.meta.token_usage,
            summary.created_at
          )}
        </>
      )}
    </>
  );
}
