import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";

const initialState = {
  all: {
    data: [],
    totalPages: 0,
    totalCount: 0,
    loading: false,
    errors: null,
  },
  current: {
    data: {},
    loading: false,
    errors: null,
    summary: null,
  },
};

export const getUsers = createAsyncThunk("users/list", async (data, thunk) => {
  try {
    let response = await API.users.getUsers(data.page, data.search);
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const downloadUsers = createAsyncThunk(
  "users/download",
  async (data, thunk) => {
    try {
      let response = await API.users.download(data.start, data.end);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const getUser = createAsyncThunk("users/show", async (data, thunk) => {
  try {
    let response = await API.users.show(data.id);
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const updateUser = createAsyncThunk(
  "users/update",
  async (data, thunk) => {
    try {
      let response = await API.users.update(data.id, data.data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const getLatestConversation = createAsyncThunk(
  "users/latestConversation",
  async (data, thunk) => {
    try {
      let response = await API.users.latestConversation(data?.id);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const getLatestSummary = createAsyncThunk(
  "users/latestSummary",
  async (data, thunk) => {
    try {
      let response = await API.users.summary(
        data?.conversationId,
        data?.userId
      );
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const resetSummary = createAsyncThunk(
  "users/resetSummary",
  async (data, thunk) => {
    try {
      let response = await API.users.resetSummary(data.id);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const forceSummary = createAsyncThunk(
  "users/forceSummary",
  async (data, thunk) => {
    try {
      let response = await API.users.forceSummary(data.id);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const resetProfile = createAsyncThunk(
  "users/resetProfile",
  async (data, thunk) => {
    try {
      let response = await API.users.resetProfile(data.id, data.data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
  },
  extraReducers: {
    [getUsers.pending]: (state) => {
      state.all.loading = true;
    },
    [getUsers.fulfilled]: (state, action) => {
      state.all.loading = false;
      state.all.data = action.payload.users;
      state.all.totalPages = action.payload.total_pages;
      state.all.totalCount = action.payload.total_count;
    },
    [getUsers.rejected]: (state, action) => {
      state.all.loading = false;
      state.all.data = [];
      state.all.totalPages = 0;
      state.all.errors = ["Error during data retrieval!"];
    },
    [getUser.pending]: (state) => {
      state.current.loading = true;
      state.current.data = {};
    },
    [getUser.fulfilled]: (state, action) => {
      state.current.loading = false;
      state.current.data = action.payload;
    },
    [getUser.rejected]: (state, action) => {
      state.current.loading = false;
      state.current.errors = ["Error during data retrieval!"];
    },
  },
});

export const { clearErrors } = usersSlice.actions;

export const usersSelector = (state) => state.users.all;
export const currentUserSelector = (state) => state.users.current;

export default usersSlice.reducer;
