import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";

const initialState = {
  data: [],
  loading: false,
  errors: null,
  totalPages: 0,
  totalCount: 0,
};

export const getLinks = createAsyncThunk("links/index", async (data, thunk) => {
  try {
    let response = await API.links.index(data.page, data.per);
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const createLink = createAsyncThunk(
  "links/create",
  async (data, thunk) => {
    try {
      let response = await API.links.create(data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const updateLink = createAsyncThunk(
  "links/update",
  async (data, thunk) => {
    try {
      let response = await API.links.update(data.id, data.data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const deleteLink = createAsyncThunk(
  "links/delete",
  async (id, thunk) => {
    try {
      let response = await API.links.delete(id);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const linksSlice = createSlice({
  name: "links",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
  },
  extraReducers: {
    [getLinks.pending]: (state) => {
      state.loading = true;
    },
    [getLinks.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload.links;
      state.totalPages = action.payload.total_pages;
      state.totalCount = action.payload.total_count;
    },
    [getLinks.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
  },
});

export const { clearErrors } = linksSlice.actions;

export const linksSelector = (state) => state.links;

export default linksSlice.reducer;
