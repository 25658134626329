import {
  DeleteOutlined,
  PlusOutlined,
  PlusSquareOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Space,
  Table,
  Tooltip,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Metric } from "../../components/Metric/Metric";
import { metricsSelector, refreshMetrics } from "../nav/navSlice";
import {
  createSurvey,
  downloadResponses,
  getSurveys,
  surveysSelector,
  updateSurvey,
} from "./surveysSlice";

const PAGE_SIZE = 50;
const { TextArea } = Input;

export function Surveys() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { data: metrics, loading: metricsLoading } =
    useSelector(metricsSelector);
  const {
    data: surveys,
    loading,
    errors,
    totalPages,
    totalCount,
  } = useSelector(surveysSelector);
  const [pageNum, setPageNum] = useState(1);
  const [currentSurvey, setCurrentSurvey] = useState(null);
  const [editOpened, setEditOpened] = useState(false);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error(errors[0]);
    }
  }, [errors]);

  useEffect(() => {
    dispatch(
      getSurveys({
        page: pageNum,
        per: PAGE_SIZE,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      refreshMetrics({
        filter: "surveys",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (currentSurvey) {
      form.setFieldsValue({
        ...currentSurvey,
        questions: currentSurvey.questions.map((q) => ({ question: q })),
      });
    }
  }, [currentSurvey, form]);

  const resetForm = () => {
    setEditOpened(false);
    form.resetFields();
    setCurrentSurvey(null);
  };

  const onPageChange = (page) => {
    setPageNum(page);
    dispatch(
      getSurveys({
        page: page,
        per: PAGE_SIZE,
      })
    );
  };

  const downloadCsv = (id) => {
    dispatch(downloadResponses({ id })).then((res) => {
      if (res.type === "surveys/downloadResponses/fulfilled") {
        const url = window.URL.createObjectURL(new Blob([res.payload]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `survey-${id}-${new Date().toLocaleString()}.csv`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        message.error("Failed to download survey responses");
      }
    });
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (n, s) => (
        <span
          className="link"
          onClick={() => {
            setCurrentSurvey(s);
            setEditOpened(true);
          }}
        >
          {n}
        </span>
      ),
    },
    {
      title: "Questions",
      dataIndex: "questions",
      key: "questions",
      render: (q) => q?.length,
    },
    {
      title: "Responses",
      dataIndex: "responses_c",
      key: "responses_c",
      render: (c, s) => (
        <span className="link" onClick={() => downloadCsv(s.id)}>
          {c} {c === 1 ? "response" : "responses"}
        </span>
      ),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => new Date(created_at).toLocaleString(),
    },
  ];

  const saveSurvey = (values) => {
    if (!values?.questions?.length) {
      message.error("Please add questions to the survey");
      return;
    }

    const data = {
      ...values,
      questions: values.questions.map((q) => q.question),
    };
    if (!currentSurvey?.id) {
      dispatch(createSurvey(data)).then((res) => {
        if (res.type === "surveys/create/fulfilled") {
          message.success("Survey created successfully");
          resetForm();
          dispatch(
            getSurveys({
              page: pageNum,
              per: PAGE_SIZE,
            })
          );
        } else {
          message.error("Failed to create survey");
        }
      });
    } else {
      dispatch(updateSurvey({ id: currentSurvey.id, data })).then((res) => {
        if (res.type === "surveys/update/fulfilled") {
          message.success("Survey updated successfully");
          resetForm();
          dispatch(
            getSurveys({
              page: pageNum,
              per: PAGE_SIZE,
            })
          );
        } else {
          message.error("Failed to update survey");
        }
      });
    }
  };

  return (
    <>
      <div className="container">
        <div className="mb-15">
          <Row justify="space-between" wrap>
            <Col>
              <span className="fs-5 fw-300">Surveys</span>
            </Col>
            <Col className="ta-r">
              <Button
                icon={<PlusOutlined />}
                type="primary"
                onClick={(e) => setEditOpened(true)}
              >
                New
              </Button>
            </Col>
          </Row>
        </div>
        <div className="mb-30">
          <Row gutter={24}>
            <Col span={6}>
              <Metric
                title="Surveys"
                current={metrics["total_c"]}
                loading={metricsLoading}
              />
            </Col>
            <Col span={6}>
              <Metric
                title="Responses"
                current={metrics["responses_c"]}
                loading={metricsLoading}
              />
            </Col>
          </Row>
        </div>
        <div className="container bg-white b-shadow">
          <Table
            loading={loading}
            bordered
            size="small"
            columns={columns}
            className="mt-10"
            dataSource={surveys.map((l, i) => {
              return { ...l, ...{ key: i } };
            })}
            pagination={{
              position: ["bottomRight"],
              onChange: onPageChange,
              pageSize: PAGE_SIZE,
              total: totalPages * PAGE_SIZE,
              showSizeChanger: false,
              current: pageNum,
              showTotal: (total) => (
                <span className="fs-2 c-subtext">{`${totalCount} ${
                  totalCount === 1 ? "survey" : "surveys"
                }`}</span>
              ),
            }}
          />
        </div>
      </div>

      <Modal
        title={
          currentSurvey?.id ? `Survey #${currentSurvey?.id}` : `New Survey`
        }
        footer={null}
        disabled={currentSurvey?.id}
        destroyOnClose={true}
        closable={true}
        maskClosable={false}
        open={editOpened}
        width={800}
        style={{
          top: 32,
        }}
        onCancel={() => resetForm()}
      >
        <div className="mt-30">
          <Form
            form={form}
            layout="vertical"
            onFinish={saveSurvey}
            requiredMark={false}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please input survey name" }]}
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item
              label={
                <div>
                  Success Message&nbsp;&nbsp;&nbsp;
                  <Tooltip
                    title="Your instructions to Casey for what to communicate to the user after completing the survey."
                    color="#4867b1"
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </div>
              }
              name="success"
              rules={[
                { required: true, message: "Please input a success message" },
              ]}
            >
              <TextArea
                autoSize={true}
                placeholder="Tell the user they have completed the survey. Thank the user for their time and for sharing their thoughts."
              />
            </Form.Item>
            <Form.List name="questions">
              {(fields, { add, remove }, { errors }) => (
                <>
                  <Space className="mb-10">
                    <div className="fw-600">Questions</div>
                    <Button
                      type="link"
                      onClick={add}
                      disabled={currentSurvey?.id}
                    >
                      <PlusSquareOutlined />
                    </Button>
                  </Space>
                  {fields.map((field, index) => (
                    <Row gutter={12} align="middle" key={index}>
                      <Col span={2} className="ta-r">
                        <Form.Item>Q{index + 1}:</Form.Item>
                      </Col>
                      <Col span={20}>
                        <Form.Item
                          name={[index, "question"]}
                          rules={[
                            {
                              required: true,
                              message: "Please input a question",
                            },
                          ]}
                        >
                          <TextArea autoSize disabled={currentSurvey?.id} />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item>
                          <Button
                            danger
                            type="link"
                            onClick={() => remove(field.name)}
                            disabled={currentSurvey?.id}
                          >
                            <DeleteOutlined />
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                </>
              )}
            </Form.List>
            <Form.Item className="ta-r mt-30">
              <Button type="primary" htmlType="submit">
                {currentSurvey?.id ? "Update" : "Create"}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
}
