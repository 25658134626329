import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";

const initialState = {
  data: [],
  loading: false,
  errors: null,
  totalPages: 0,
  totalCount: 0,
};

export const getSurveys = createAsyncThunk(
  "surveys/index",
  async (data, thunk) => {
    try {
      let response = await API.surveys.index(data.page, data.per);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const getSurvey = createAsyncThunk("surveys/show", async (id, thunk) => {
  try {
    let response = await API.surveys.show(id);
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const createSurvey = createAsyncThunk(
  "surveys/create",
  async (data, thunk) => {
    try {
      let response = await API.surveys.create(data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const updateSurvey = createAsyncThunk(
  "surveys/update",
  async (data, thunk) => {
    try {
      let response = await API.surveys.update(data.id, data.data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const deleteSurvey = createAsyncThunk(
  "surveys/delete",
  async (id, thunk) => {
    try {
      let response = await API.surveys.delete(id);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const downloadResponses = createAsyncThunk(
  "surveys/downloadResponses",
  async (data, thunk) => {
    try {
      let response = await API.surveys.downloadResponses(data.id);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const searchSurveyIds = createAsyncThunk(
  "surveys/searchSurveyIds",
  async (data, thunk) => {
    try {
      let response = await API.discover.searchSurveys(data.query);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const surveysSlice = createSlice({
  name: "surveys",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.surveys.errors = null;
    },
  },
  extraReducers: {
    [getSurveys.pending]: (state) => {
      state.loading = true;
    },
    [getSurveys.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload.surveys;
      state.totalPages = action.payload.total_pages;
      state.totalCount = action.payload.total_count;
    },
    [getSurveys.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
  },
});

export const { clearErrors } = surveysSlice.actions;

export const surveysSelector = (state) => state.surveys;

export default surveysSlice.reducer;
